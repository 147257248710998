var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "b-navbar",
          { attrs: { type: "dark", variant: "info" } },
          [
            _c("b-navbar-brand", [_vm._v("CEDUR 3.0 (TEST)")]),
            _c(
              "b-navbar-nav",
              { staticClass: "ml-auto" },
              [
                _c(
                  "b-nav-item-dropdown",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(_vm.me.number || _vm.me.patient.number)
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      { attrs: { to: { name: "auth-logout" } } },
                      [_vm._v("Logout")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("b-toaster", { staticClass: "toaster", attrs: { name: "app" } }),
          _c("nuxt")
        ],
        1
      )
    ]),
    _c("div", { staticClass: "footer" }, [
      _c("hr"),
      _c(
        "div",
        { staticClass: "text-center mb-3 small" },
        [
          _c(
            "b-link",
            { attrs: { to: { name: "site-notice" }, target: "_blank" } },
            [_vm._v("Impressum & Datenschutz")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }